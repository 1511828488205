<template>
    <div @click="if(!readonly){modelValue = !modelValue; handleChange()}" class="group cursor-pointer transition-all relative duration-500 flex gap-1 py-1 items-center" v-bind:class="{
        'visible opacity-100' : elementActive,
        'invisible opacity-25' : !elementActive,
    }">
        <input type="checkbox" :value="modelValue" :disabled="disabled" :readonly="readonly" @change="handleChange"
            class="hidden userdata" />
        <div class="userdata rounded-full shrink-0 overflow-hidden relative transition-color duration-300 bg-transparent p-2.5 group-hover:bg-blue-100 dark:group-hover:bg-primary
            before:content-[''] before:z-0 before:absolute before:block before:scale-0 before:w-full before:h-full before:top-0 before:left-0 before:rounded-full before:bg-primary before:bg-opacity-40
            before:origin-bottom-left before:transition-all before:duration-300 before:opacity-0
            group-active:before:scale-100 group-active:before:opacity-100 after:transition-all after:duration-300"
            v-bind:class="{
                'group-active:after:opacity-0 group-hover:after:opacity-0 after:content-[\'\'] after:z-0 after:absolute after:block after:w-full after:h-full after:top-0 after:left-0 after:rounded-full after:bg-red-300 after:bg-opacity-40' : error.show,
            }">
            <div class="rounded z-50 border-2 shadow shadow-blue-200 border-slate-700 dark:border-gray-400 dark:shadow-none p-0.5 flex items-center justify-center">
                <ElementsIcon v-if="modelValue" size="small" icon="checkmark" />
                <ElementsIcon v-else size="small" icon="empty" />
            </div>
        </div>
        <div class="flex gap-1 items-center">
            <slot name="label">
                <label v-if="label">
                    {{label}}
                    <span class="inline-block text-gray-400" v-if="required">*</span>
                </label>
            </slot>
            <LazyElementsHelpBubble v-if="path" :path="path" />
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    type: { type: String, default: 'text' },
    label: { type: String },
    modelValue: { type: Boolean },
    disabled: { type: String },
    required: { type: Boolean, default: false },
    hasToBeTrue: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    
    path: { type: String, default: '' },
    validation: { type: Object, default: { autovalidate: () => {}, add: () => {}, remove: () => {} } },
})
const emit = defineEmits(['update:modelValue', 'toggle', 'error'])

const error = reactive({
    show: false,
    message: '',
})

const requiredValidation = () => useHandleValidation(props.modelValue != null, 'Dieses Feld ist erforderlich', error, emit)
const hasToBeTrueValidation = () => useHandleValidation(props.modelValue == true, 'Dieses Feld ist erforderlich', error, emit)

const handleChange = (e) => {
    error.show = false
    emit('update:modelValue', props.modelValue)
    emit('toggle', props.modelValue)
}


const elementActive = ref(false)
onMounted(function(){
    setTimeout(function(){ elementActive.value = true }, 100)

    if(props.required) props.validation.add(props.path, requiredValidation)
    if(props.hasToBeTrue) props.validation.add(props.path, hasToBeTrueValidation)
})
onBeforeUnmount(() => elementActive.value = false )
onUnmounted(function(){
    props.validation.remove(props.path)
})
</script>